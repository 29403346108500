import React from 'react';

const Disclaimer = () => {
  return (
    <div>
      <h6 className="services-disclaimer">*Prices subject to change without notice.</h6>
    </div>
  );
};
export default Disclaimer;
