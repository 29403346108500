import React from 'react';

const NailEnhancements = () => {
  return (
    <div className='menu-bot smaller-menu'>
      <h5 className='services-sub'>NAIL ENHANCEMENTS</h5>
      <table className='table-menu'>
        <tbody>
          <tr>
            <td className='column-block'>
              <div>
                <strong className='menu menu-title'>Acrylic</strong>
              </div>
            </td>
            <td className='column-block menu-price small-menu-text-left'>
              <span>Full Set: $35 | Fill: $25</span>
            </td>
          </tr>
          <tr>
            <td className='column-block'>
              <div>
                <strong className='menu menu-title'>Pink &amp; White</strong>
              </div>
            </td>
            <td className='column-block menu-price small-menu-text-left'>
              <span>Full Set: $45 | Fill: $25</span>
            </td>
          </tr>
          <tr>
            <td className='column-block'>
              <div>
                <strong className='menu menu-title'>Gel Fill Color</strong>
              </div>
            </td>
            <td className='column-block menu-price small-menu-text-left'>
              <span>$38</span>
            </td>
          </tr>
          <tr>
            <td className='column-block'>
              <div>
                <strong className='menu menu-title'>
                  Acrylic With Gel Color
                </strong>
              </div>
            </td>
            <td className='column-block menu-price small-menu-text-left'>
              <span>$50</span>
            </td>
          </tr>
          <tr>
            <td className='column-block'>
              <div>
                <strong className='menu menu-title'>
                  "Pink &amp; White" Gel With Topcoat
                </strong>
              </div>
            </td>
            <td className='column-block menu-price small-menu-text-left'>
              <span>$50</span>
            </td>
          </tr>
          <tr>
            <td className='column-block'>
              <div>
                <strong className='menu menu-title'>
                  Glitter French Full Set
                </strong>
              </div>
            </td>
            <td className='column-block menu-price small-menu-text-left'>
              <span>$50</span>
            </td>
          </tr>
          <tr>
            <td className='column-block'>
              <div>
                <strong className='menu menu-title'>
                  Ombre French Full Set
                </strong>
              </div>
            </td>
            <td className='column-block menu-price small-menu-text-left'>
              <span>$55</span>
            </td>
          </tr>
          <tr>
            <td className='column-block'>
              <div>
                <strong className='menu menu-title'>Buff Nail Shine</strong>
              </div>
            </td>
            <td className='column-block menu-price small-menu-text-left'>
              <span>$5</span>
            </td>
          </tr>
          <tr>
            <td className='column-block'>
              <div>
                <strong className='menu menu-title'>Dipping Nail</strong>
              </div>
            </td>
            <td className='column-block menu-price small-menu-text-left'>
              <span>$55</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default NailEnhancements;
