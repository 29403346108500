import React from 'react';

const AdditionalServices = () => {
  return (
    <div className='menu-bot smaller-menu'>
      {/* <h5 className="services-sub">ADDITIONAL SERVICES</h5> */}
      <table className='table-menu'>
        <tbody>
          <tr>
            <td className='column-block'>
              <div>
                <strong className='menu menu-title'>Gel Topcoat</strong>
              </div>
            </td>
            <td className='column-block menu-price small-menu-text-left'>
              <span>$7</span>
            </td>
          </tr>
          <tr>
            <td className='column-block'>
              <div>
                <strong className='menu menu-title'>Nail Repair</strong>
              </div>
            </td>
            <td className='column-block menu-price small-menu-text-left'>
              <span>$5</span>
            </td>
          </tr>
          <tr>
            <td className='column-block'>
              <div>
                <strong className='menu menu-title'>French Polish Extra</strong>
              </div>
            </td>
            <td className='column-block menu-price small-menu-text-left'>
              <span>$10</span>
            </td>
          </tr>
          <tr>
            <td className='column-block'>
              <div>
                <strong className='menu menu-title'>
                  French Polish Change
                </strong>
              </div>
            </td>
            <td className='column-block menu-price small-menu-text-left'>
              <span>$10</span>
            </td>
          </tr>
          <tr>
            <td className='column-block'>
              <div>
                <strong className='menu menu-title'>
                  Acrylic Nail Removal
                </strong>
              </div>
            </td>
            <td className='column-block menu-price small-menu-text-left'>
              <span>$10</span>
            </td>
          </tr>
          <tr>
            <td className='column-block'>
              <div>
                <strong className='menu menu-title'>Callus Removal</strong>
              </div>
            </td>
            <td className='column-block menu-price small-menu-text-left'>
              <span>$10</span>
            </td>
          </tr>
          <tr>
            <td className='column-block'>
              <div>
                <strong className='menu menu-title'>Nail Design</strong>
              </div>
            </td>
            <td className='column-block menu-price small-menu-text-left'>
              <span>$5+</span>
            </td>
          </tr>
          <tr>
            <td className='column-block'>
              <div>
                <strong className='menu menu-title'>
                  Polish Change (hands)
                </strong>
              </div>
            </td>
            <td className='column-block menu-price small-menu-text-left'>
              <span>$8+</span>
            </td>
          </tr>
          <tr>
            <td className='column-block'>
              <div>
                <strong className='menu menu-title'>
                  Polish Change (toes)
                </strong>
              </div>
            </td>
            <td className='column-block menu-price small-menu-text-left'>
              <span>$10+</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default AdditionalServices;
