import React from 'react';

const Wax = () => {
  return (
    <div className="menu-bot smaller-menu">
      <h5 className="services-sub">WAXING</h5>
      <table className="table-menu">
        <tbody>
          <tr>
            <td className="column-block">
              <div>
                <strong className="menu menu-title">Eyebrows</strong>
              </div>
            </td>
            <td className="column-block menu-price small-menu-text-left">
              <span>$18</span>
            </td>
          </tr>
          <tr>
            <td className="column-block">
              <div>
                <strong className="menu menu-title">Lip</strong>
              </div>
            </td>
            <td className="column-block menu-price small-menu-text-left">
              <span>$12</span>
            </td>
          </tr>
          <tr>
            <td className="column-block">
              <div>
                <strong className="menu menu-title">Chin</strong>
              </div>
            </td>
            <td className="column-block menu-price small-menu-text-left">
              <span>$18</span>
            </td>
          </tr>
          <tr>
            <td className="column-block">
              <div>
                <strong className="menu menu-title">Half Leg</strong>
              </div>
            </td>
            <td className="column-block menu-price small-menu-text-left">
              <span>$50+</span>
            </td>
          </tr>
          <tr>
            <td className="column-block">
              <div>
                <strong className="menu menu-title">Full Face</strong>
              </div>
            </td>
            <td className="column-block menu-price small-menu-text-left">
              <span>$50</span>
            </td>
          </tr>
          <tr>
            <td className="column-block">
              <div>
                <strong className="menu menu-title">Under Arm</strong>
              </div>
            </td>
            <td className="column-block menu-price small-menu-text-left">
              <span>$18</span>
            </td>
          </tr>
          <tr>
            <td className="column-block">
              <div>
                <strong className="menu menu-title">Back</strong>
              </div>
            </td>
            <td className="column-block menu-price small-menu-text-left">
              <span>$50+</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default Wax;
